.stamp-btn-container{
    padding: 3rem;
    background-color:rgb(246, 243, 243);
.Stamp1-container{
   display: grid;
    grid-template-columns: 60% 40%;
    margin-top:2rem;
    .stamp-input {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin: auto;

        .label {
            color: #999999;
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 7px;

        }

        .input-value {
            border: 1px solid #DADADA;
            border-radius: 6px;
            width: 100%;
            height: 40px;
            padding: 4px;
            background-color: rgb(246, 246, 246);
            outline: none;
          

        }
    }
    .stamp1-img{
        width: 300px;
        height: 300px;
        margin: auto;
        position: relative;
        .business-stamp{
            position: absolute;
            top: 39%;
            width: 100%;
        .business-stamp-name{
            font-size: 30px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
        }
    }
    }
}

.download-stamp-btn {
    margin-top: 3rem;
    width: 100%;
    height: 35px;

    .titles {
        margin: auto;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0293A8;
        color: white;
        border-radius: 5px;
        height: 100%;
    }
}
}

@media screen and (width <= 620px) {
    .stamp-container .template-stamp-container .Stamps1,.stamp-container .template-stamp-container .Stamps2{
        width: unset;
        height: unset;
    }
    .stamp-container .template-stamp-container .Stamps1 .stamp1,
    .stamp-container .template-stamp-container .Stamps2 .stamp2,
    .stmp1{
        width: 100px;
    }
    .stamp-btn-container .download-stamp-btn{
        margin: unset;
        width: unset;
        height: unset;
    }
}