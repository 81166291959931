.billbook-con{
    background-color: rgb(246, 243, 243);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding-top: 20px;
    align-items: center;
    margin-bottom: 30px;
    .billbook-add-btn-con{
        width: 80%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .add-btn{
            height: 40px;
            width: 120px;
            background-color: #0293A8;
            color: #fff;
            border-radius: 8px;
        }
    }
    .bills{
        height: 80%;
        width: 80%;
        margin-top: 1em;
        overflow: hidden;
        overflow-y: scroll;
        background-color: #fff;
        padding: 12px;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        .biils-h{
            width: 100%;
            // display: grid;
            // align-items: center;
            // justify-content: center;
            // grid-template-columns: 15% 20% 30% 20% 15%;
            // text-align: center;
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid #999999;
            padding-bottom: 10px;
            font-weight: 600;
            font-size: 16px;
        }
    }
}

.empty-bill-con{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .no-bills-h{
        font-size: 22px;
        font-weight: 500;
    }
}

.invoice-stamp{
    height: 100px;
    margin-left: 3em;
    margin-top: 2em;
    background-size: cover;
}