.bill-details {
    padding-top: 2rem;
    background-color: rgb(246, 243, 243);
    // height: 100%;
    padding-bottom: 30px;
    .bill-txt {
        color: #999999;
        font-size: 20px;
        font-weight: 500;
        margin: 1rem 0 1rem 3rem;
    }

    .bill-form {
        display: flex;
        justify-content: center;

        .forms {
            // display: flex;
            width: 90%;
            // justify-content: space-between;
            display: flex;
            // flex-direction: column;
            gap: 20px;
            padding: 20px;
            background-color: white;
            border-radius: 10px;
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            .left-form {
                // width: 40%;

                .input-val {
                    display: flex;
                    flex-direction: column;
                    .label {
                        color: #999999;
                        font-weight: 600;
                    }
                    .inp {
                        border: 2px;
                        margin-bottom: 0.5rem;
                        border-radius: 6px;
                        height: 40px;
                        border: 1px solid rgb(207, 203, 203);
                        outline: none;
                        padding: 0 1rem;
                        background-color: #ffffff;
                    }
                }

                .hafl-input-val {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .val {
                        width: 46%;

                        .label {
                            color: #999999;
                            font-weight: 600;
                        }

                        .inp {
                            border: 2px;
                            width: 100%;
                            margin-bottom: 0.5rem;
                            height: 40px;
                            border-radius: 6px;
                            border: 1px solid rgb(207, 203, 203);
                            outline: none;
                            padding: 0 1rem;
                        }

                    }
                }
                    .buttons-con{
                        margin-top: 2em;
                        display: flex;
                        // justify-content: center;
                        align-items: center;
                        .bill-back-btn{
                            width: 100px;
                            height: 40px;
                            border-radius: 8px;
                            background-color: transparent;
                            border: 1px solid #999999;
                            margin-right: 12px;
                            font-weight: 600;
                            color: #595959;
                        }
                        .done-btn{
                            background-color: #0293A8;
                            width: 100px;
                            height: 40px;
                            border-radius: 8px;
                            border: 1px solid #999999;
                            margin-right: 12px;
                            color: #ffffff;
                            font-weight: 600;
                        }
                    }
            }

            .right-form {
                width: 55%;

                .pur-txt {
                    color: #999999;
                    font-weight: 600;

                }

                .purchase-btn {
                    display: flex;
                    height: 40px;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 2rem;

                    .ad {
                        width: 10%;
                        border: 2px solid #0293A8;
                        background-color: #0293A8;
                        border-radius: 5px;
                        font-size: 30px;
                        text-align: center;
                        color: white;
                    }

                    .purchase-div {
                        width: 88%;
                        background-color: #ffffff;
                        border: 2px;
                        display: flex;
                        border-radius: 6px;
                        cursor: pointer;
                        align-items: center;
                        padding-left: 2rem;
                        font-weight: 500;
                        border: 1px solid rgb(207, 203, 203);

                    }
                }

                .prod-list {
                    width: 100%;
                    height: 50vh;
                    overflow: hidden;
                    overflow-y: scroll;
                    padding: 1em;
                    .prods {
                        position: relative;
                        display: grid;
                        grid-template-columns: 10% 25% 15% 23% 22% 5%;
                        width: 100%;
                        border: 2px solid #DADADA;
                        height: 60px;
                        align-items: center;
                        text-align: center;
                        padding: 0 0.5rem;
                        margin-bottom: 1rem;
                        background-color: #F5F5F5;
                        border-radius: 8px;
                        font-size: 14px;
                        .prod-img {
                            width: 40px;
                            height: 40px;
                            padding: 2px;
                            border: 1px solid #0293A8;
                            border-radius: 2px;

                        }

                        .prod-qtn {
                            display: flex;

                            .txt {
                                color: #7b7a7a;
                            }
                        }

                        .prod-size {
                            display: flex;
                            .txt {
                                color: #7b7a7a;
                            }
                        }

                        .prod-color {
                            display: flex;
                            .color{
                                height: 20px;
                                width: 30px;
                                border-radius: 6px;
                                margin-left: 6px;
                            }
                            .txt {
                                color: #7b7a7a;
                            }
                        }

                        .prodc-btn {
                            position: absolute;
                            color: white;
                            font-weight: 400;
                            height: 24px;
                            width: 24px;
                            right: -11px;
                            top: -12px;
                            border: 2px solid #0293A8;
                            border-radius: 50%;
                            background-color: #0293A8;
                        }
                    }
                }

            }
        }
    }
}

.p-div {
    // height: 100%;
    background-color: rgb(246, 243, 243);
    .less-icon {
        color: #0293A8;
        margin-left: 1rem;
        margin-bottom: 2rem;
        margin-top: 2rem;

    }
    .bill-form-done-btn{
        background-color: #0293A8;
        border: 0px solid;
        border-radius: 12px;
        width: 120px;
        height: 35px;
        margin-right: 1em;
        color: #ffffff;
        font-weight: 600;
    }

    .prod-catalogue {
        // height: 68vh;
        // overflow: hidden;
        // overflow-y: scroll;
        display: flex;
        justify-content: center;
        .products-catalogue-con {
            // display: grid;
            // grid-template-columns: 30% 30% 30%;
            // justify-content: center;
            // gap: 2.5%;
            // width: 90%;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            padding: 20px;
            
        }
    }
}


@media screen and (width <= 620px) {
    .bill-details{
        margin-bottom: 70px;
        padding-top: unset;
        padding-bottom: 40px;
    }
    .p-div .prod-catalogue .products-catalogue-con{
        margin-bottom: 70px;
    }
    .bill-details .bill-txt{
        padding: 20px;
        margin: unset;
    }
    .bill-details .bill-form .forms{
        flex-direction: column;
    }

    .bill-details .bill-form .forms .right-form .purchase-btn{
        margin: unset;
    }
    .bill-details .bill-form .forms .right-form .prod-list{
        width: unset;
        height: unset;
        overflow: unset;
        margin: unset;
        padding: unset;
        margin: 20px 0;
    }
    .bill-details .bill-form .forms .right-form .prod-list .prods {
        display: flex !important;
        gap: 20px !important;
    }

    .bill-details .bill-form .forms .right-form{
        width: unset;
    }
}