.password-bg-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .e-note {
    font-size: 14px;
    color: #999999;
    margin-top: 1rem;
    .red-note {
      color: crimson;
      font-weight: 600;
    }
  }
  .password-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30vw;
    padding-top: 3em;
    padding-bottom: 3em;
    background-color: rgb(244, 244, 244);
    box-shadow: 3px 3px 5px #999999;
    border-radius: 6px;
    .p-heding {
      font-size: 20px;
      font-weight: 600;
    }
    .p-description {
      font-size: 14px;
      margin-top: 6px;
      font-weight: 300;
      color: #999999;
      margin-bottom: 3em;
    }
    .p-form {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .p-label {
        width: 95%;
        color: #999999;
        font-size: 14px;
        margin-top: 12px;
        margin-bottom: 2px;
      }
      .password-container {
        width: 100%;
        display: grid;
        grid-template-columns: 90% 10%;
        align-items: center;
        justify-content: space-around;
        background-color: azure;
        border-radius: 4px;
        border-bottom: 1px solid #0293a8;
        .eye-icon {
          font-size: 22px;
          margin-left: 6px;
        }
        .password-in {
          background-color: transparent;
          outline: none;
          padding: 4px;
          font-size: 16px;
          font-weight: 300;
        }
      }
      .nor-in {
        width: 100%;
        background-color: transparent;
        outline: none;
        padding: 4px;
        font-size: 16px;
        font-weight: 300;
        background-color: azure;
        border-radius: 4px;
        border-bottom: 1px solid #0293a8;
      }
      .login-btn {
        margin-top: 1rem;
        width: 80%;
        height: 40px;
        border: 0px solid;
        border-radius: 12px;
        outline: none;
        background-color: transparent;
        background-image: linear-gradient(153.72deg, #acdddb, #f0fbcf 98.96%);
        color: #0293a8;
        font-weight: 700;
        cursor: pointer;
      }
    }
    .otp-bg-container {
      flex-shrink: 0;
      width: 100%;
      display: grid;
      grid-template-columns: 90%;
      grid-template-rows: 90% 10%;
      justify-content: center;
      align-items: center;
      .otp-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .otp-heading {
          color: #999999;
          font-weight: 300;
          width: 100%;
          margin-bottom: 1rem !important;
          text-align: left;
          margin-top: 1em;
          .number {
            color: #97cbd3;
            font-weight: 500;
          }
        }
        .Otp {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1.5rem;
          .otp-field {
            width: 40px;
            height: 40px;
            text-align: center;
            border: 1px solid #999999;
            border-radius: 6px;
            margin-left: 5px;
            margin-right: 5px;
            outline: none;
            color: #999999;
            font-weight: 700;
          }
        }
        .ver-btn {
          width: 80%;
          height: 40px;
          border: 0px solid;
          border-radius: 12px;
          outline: none;
          background-color: transparent;
          background-image: linear-gradient(153.72deg, #acdddb, #f0fbcf 98.96%);
          color: #0293a8;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .password-bg-container {
    padding: 1em;
  }

  .password-bg-container .password-form {
    width: 80vw;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .password-bg-container .password-form .p-heding {
    font-size: 18px;
  }

  .password-bg-container .password-form .p-description {
    font-size: 12px;
    margin-bottom: 2em;
  }

  .password-bg-container .password-form .p-form {
    width: 100%;
  }

  .password-bg-container .password-form .p-form .p-label {
    font-size: 12px;
    margin-top: 8px;
  }

  .password-bg-container .password-form .p-form .password-container {
    grid-template-columns: 85% 15%;
  }

  .password-bg-container .password-form .p-form .password-container .eye-icon {
    font-size: 20px;
  }

  .password-bg-container
    .password-form
    .p-form
    .password-container
    .password-in {
    font-size: 14px;
  }

  .password-bg-container .password-form .p-form .nor-in {
    font-size: 14px;
  }

  .password-bg-container .password-form .p-form .login-btn {
    width: 90%;
    height: 35px;
    font-size: 14px;
  }

  .password-bg-container .password-form .otp-bg-container {
    grid-template-columns: 100%;
    grid-template-rows: 80% 20%;
  }

  .password-bg-container
    .password-form
    .otp-bg-container
    .otp-container
    .otp-heading {
    font-size: 12px;
  }

  .password-bg-container
    .password-form
    .otp-bg-container
    .otp-container
    .Otp
    .otp-field {
    width: 35px;
    height: 35px;
  }

  .password-bg-container
    .password-form
    .otp-bg-container
    .otp-container
    .ver-btn {
    width: 90%;
    height: 35px;
    font-size: 14px;
  }
}
