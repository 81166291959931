.profile-container {
  // width: 100vw;
  // height: 100vh;
  background-color: #f5f5f5;
  overflow: hidden;
  .body-container {
    display: grid;
    grid-template-columns: 20vw 80vw;
  }
}

@media screen and (width <= 620px) {
  .body-container {
    display: flex !important;
    flex-direction: column;
    // align-items: center;
    // gap: 20px;
    padding-bottom: 100px;
  }
}
