.catalogue-bg-container{
    height: 100vh;
    overflow-y: scroll;
    .header-outlet-container{
        display: grid;
        grid-template-columns: 25vw 75vw;
        grid-auto-rows: calc(100vh - 98px);
    }
} 




@media screen and (width <= 620px) {
    .catalogue-bg-container .header-outlet-container{
        display: unset;
    }
    .sidebar-bg-container{
        padding: 20px !important;
    }
}