.card1-with-btns{
    padding: 0 3rem 0 3rem;
    background-color:rgb(246, 243, 243);
    overflow-y: scroll;
.card1-detail-container {
     display: grid;
    grid-template-columns: 50% 50%;
    

    .card1-detail {
        margin-top: 0.5rem;

        .logo-heading {
            font-size: 18px;
            color: #999999;
            font-weight: bold;
            margin-bottom: 7px;
        }

        .logo-container {
            border: 2px solid #DADADA;
            width: 55%;
            height: 9rem;
            margin-bottom: 7px;
            border-radius: 10px;

            .logo-img-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                cursor: pointer;

                .add-logo-icon {
                    color: #0293A8;

                }
            }
        }

        .half-input {
            display: flex;
            flex-direction: column;
            width: 90%;

            .label {
                color: #999999;
                font-size: 17px;
                font-weight: bold;
                margin-bottom: 7px;

            }

            .input-value {
                border: 1px solid #DADADA;
                border-radius: 6px;
                width: 100%;
                height: 40px;
                padding: 4px;
                background-color: rgb(246, 246, 246);
                outline: none;

            }
        }
    }

    .card1_preview {
        height: 16rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 15rem;
        width: 28rem;
        margin-right: 2rem;

        .card1_download {
            height: 100%;

            .preview-detail1 {
                position: absolute;
                top: .5rem;
                width: 100%;

                .logo-with-details1 {
                    display: flex;
                    justify-content: space-around;
                    margin-top: 5rem;
                    .p-details1 {
                        color: black;
                        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                        font-weight: 500;
                        font-size: 14px;
                        margin-left: 3px;
                        margin-bottom: 0.5rem;

                        .phone-number1 {
                            margin-bottom: 3px;
                        }

                        .web1 {
                            margin-bottom: 3px;
                        }

                        .email1 {
                            margin-bottom: 3px;
                        }

                        .add1 {
                            margin-bottom: 3px;
                        }
                    }
                    .logo1 {
                        width: 7rem;
                        height: 5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .business-name1{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    font-size: 24px;
                    font-weight: bold;
                    color: maroon;
                }
            }
        }
    }
}
.btns {
    display: flex;
    width: 45%;
    margin: auto;
    margin-bottom: 1rem;

    .cancel-btn {
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        .titles {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #0293A8;
            color: white;
            border-radius: 5px;
            padding: 0 3rem;
            height: 100%;
        }
    }

    .download-btn {
        margin-top: 15px;
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;

        .titles {
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #0293A8;
            color: white;
            border-radius: 5px;
            height: 100%;
        }
    }
}
   
}

@media screen and (width <= 620px) {
    .card1-with-btns .card1-detail-container .card1_preview .card1_download .preview-detail1 .business-name1{
        margin: unset;
    }
    .card1-with-btns{
        padding: 30px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 70px;
    }
    .logo1 img{
        width: 110px !important;
    }
    .card1-with-btns .card1-detail-container .card1_preview .card1_download .preview-detail1 .logo-with-details1 .p-details1{
        font-size: 12px !important;
    }
    .card1-with-btns .card1-detail-container .card1_preview .card1_download .preview-detail1{
        top: 20px !important;
    }
    .card1-with-btns .card1-detail-container{
        display: flex !important;
        flex-direction: column;
        gap: 10px;
    }
    .card1-with-btns .card1-detail-container .card1-detail{
        margin: unset;
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    .card1-with-btns .card1-detail-container .card1-detail .logo-container{
        width: 100% !important;
        height: 100px !important;
    }
    .card1-with-btns .card1-detail-container .card1-detail .half-input{
        width: 100% !important;
    }
    .card1-with-btns .card1-detail-container .card1-detail .half-input .input-value{
        background-color: white !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .card1-with-btns .card1-detail-container .card1_preview{
        height: unset;
        margin: unset;
        width: unset;
        gap: 10px;
        justify-content: unsafe;
        flex-direction: column;
    }
    .card1-with-btns .btns{
        margin: unset;
        width: unset;
        gap: 20px;
        display: flex;
    }
    .card1-with-btns .btns .cancel-btn{
        height: unset;
        width: unset;
        margin: unset;
    }
    .card1-with-btns .btns .cancel-btn .titles{
        height: unset;
        width: unset;
        padding: 5px 20px;
    }
    .card1-with-btns .btns .download-btn{
        height: unset;
        margin: unset;
        width: unset;
        justify-content: unset;
    }
    .card1-with-btns .btns .download-btn .titles{
        height: unset;
        justify-content: unset;
        width: unset;
        padding: 5px 20px;
    }
    .card1-with-btns .card1-detail-container .card1_preview .card1_download .preview-detail1{
        padding: 10px;
        display: flex !important;
        flex-direction: column;
        gap: 10px;
    }
    .card1-with-btns .card1-detail-container .card1_preview .card1_download .preview-detail1 .logo-with-details1{
        justify-content: space-around;
        margin: unset;
        gap: 20px;
    }
    .card1-with-btns .card1-detail-container .card1_preview .card1_download .preview-detail1 .logo-with-details1 .p-details1{
        margin: unset;
        width: 50%;
    }

    .card1-with-btns .card1-detail-container .card1_preview .card1_download .preview-detail1 .logo-with-details1 .logo1{
        width: 50% !important;
        height: unset;

        
    }
    .logo1{
        img{

            width: 150px;
        }
    }
}