.profile-con {
  // height: calc(100vh - 120px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow-y: scroll;
  .editdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: calc(100vh - 120px);
    .editable {
      display: grid;
      grid-template-columns: 47% 47%;
      justify-content: space-between;
      width: 90%;

      .form-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // height: 100vh;

        .product-img-heading {
          font-weight: bold;
          margin-bottom: 7px;
          color: #999999;
        }

        .add-profile-image {
          border: 1px solid #0293a8;
          border-radius: 10px;
          width: 9rem;
          height: 9rem;
          margin-bottom: 1rem;
          .add-profile-img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
            .logo {
              width: 100%;
              height: 100%;
              padding: 8px;
            }
          }

          .add-icon {
            color: #0293a8;
          }
        }
        .labels {
          margin-top: 6.75rem;
          color: #999999;
          font-weight: bold;
          margin-bottom: 4px;
        }
        .label {
          color: #999999;
          font-weight: bold;
          margin-bottom: 4px;
        }

        .inputs-value {
          border: 1px solid #dadada;
          border-radius: 6px;
          width: 100%;
          height: 40px;
          padding: 4px;
          background-color: #dadada;
          outline: none;
          margin-bottom: 1rem;
        }
        .text-value {
          border: 1px solid #dadada;
          border-radius: 6px;
          width: 100%;
          padding: 4px;
          background-color: #dadada;
          outline: none;
        }
      }
    }
  }
  .profile-btn {
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    .save-btn {
      border: 1px solid #0293a8;
      color: white;
      font-weight: bold;
      background-color: #0293a8;
      height: 40px;
      width: 45%;
      border-radius: 5px;
    }
    .cancel-btn {
      border: 1px solid #4d4d4d;
      color: rgb(48, 48, 48);
      font-weight: bold;
      height: 40px;
      width: 45%;
      border-radius: 5px;
    }
  }

  .profile {
    display: flex;
    // justify-content: ;
    align-items: center;
    gap: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 10px;
    flex-direction: row;
    padding: 20px;
    background-color: white;
    .logo {
      background-size: cover;
      border: 2px solid #0293a8;
      border-radius: 12px;
      padding: 8px;
    }

    .profileDetails {
      display: flex;
      gap: 40px;

      .proflie-keys,
      .proflie-values {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 18px;
        color: #999999;
      }
    }
  }

  .edit-btn {
    color: aliceblue;
    background-color: #0293a8;
    border-radius: 10px;
    padding: 10px 40px;
  }
}

@media screen and (width <= 620px) {
  .profile-con,
  .profile-con .editdiv {
    flex-direction: column;
    gap: 20px;
  }
  .profile-con .profile {
    gap: 20px !important;
    padding: 20px;
    flex-direction: column;
  }
  .profile-con .profile .profileDetails {
    flex-direction: row;
  }
  .profile-con .profile .profileDetails .proflie-keys,
  .profile-con .profile .profileDetails .proflie-values {
    display: unset;
  }
}
@media screen and (width <= 400px) {
  .profile-con,
  .profile-con .editdiv {
    flex-direction: column;
    gap: 20px;
  }
  .profile-con .profile {
    gap: 20px !important;
    padding: 20px;
    flex-direction: column;
  }
  .profile-con .profile .profileDetails {
    flex-direction: row;
  }
  .profile-con .profile .profileDetails .proflie-keys,
  .profile-con .profile .profileDetails .proflie-values {
    display: unset;
  }
  .proflie-values {
    font-size: small !important;
  }
  .proflie-keys {
    width: 100px;
    font-size: smaller !important;
  }
  .profile {
    width: 90% !important;
  }
  .profile-btn {
    width: 100% !important;
  }
}
