.socialCard-with-btns {
    padding: 1rem 3rem 0 3rem;
    overflow-y: scroll;
    background-color:rgb(246, 243, 243);
    .SocialCard-detail-container {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        .socialCard-detail {
            margin-top: 0.5rem;
        }

        .logo-heading {
            font-size: 18px;
            color: #999999;
            font-weight: bold;
            margin-bottom: 7px;
        }

        .logo-container {
            border: 2px solid #DADADA;
            width: 55%;
            height: 9rem;
            margin-bottom: 7px;
            border-radius: 10px;

            .logo-img-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                cursor: pointer;

                .add-logo-icon {
                    color: #0293A8;

                }
            }
        }

        .half-input {
            display: flex;
            flex-direction: column;
            width: 90%;
            margin-top: 10px;

            .label {
                color: #999999;
                font-size: 17px;
                font-weight: bold;
                margin-bottom: 7px;

            }

            .input-value {
                border: 1px solid #DADADA;
                border-radius: 6px;
                width: 100%;
                height: 40px;
                padding: 4px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                // background-color: rgb(246, 246, 246);
                outline: none;

            }

            .input-box {
                border: 1px solid #DADADA;
                border-radius: 6px;
                width: 50%;
                height: 40px;
                display: flex;

                .input-colors {
                    width: 15%;
                    height: 95%;
                    margin: 1px 12px 1px 8px;
                    outline: none;
                }

                .colore-name {
                    font-weight: bold;
                    text-align: center;
                    margin: auto;
                    margin-left: 1rem;
                }
            }
        }
        .color-container {
            display: flex;
            width: 90%;
            justify-content: space-between;
            margin-bottom: 1rem;
            margin-top: 1rem;

            .halfs-input {
                width: 45%;
                display: flex;
                flex-direction: column;

                .label {
                    color: #999999;
                    font-size: 17px;
                    font-weight: bold;
                    margin-bottom: 7px;

                }

                .input-boxs {
                    border: 1px solid #DADADA;
                    border-radius: 6px;
                    width: 100%;
                    height: 40px;
                    display: flex;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                    .input-color {
                        width: 20%;
                        height: 95%;
                        margin: 1px 12px 1px 8px;
                        outline: none;

                    }

                    .color-name {
                        font-weight: bold;
                        text-align: center;
                        margin: auto;
                        margin-left: 1rem;
                    }
                }
            }
        }





        .socialCard_preview {
            height: 22rem;
            display: flex;
            // justify-content: center;
            // align-items: center;
            // margin-top: 8rem;
            // width: 28rem;
            // margin-right: 2rem;
            gap: 20px;
            flex-direction: column;

            .half-inputs {
                display: flex;
                flex-direction: column;
                // width: 100%;
                // margin-bottom: 1rem;
    
                .labels {
                    color: #999999;
                    font-size: 17px;
                    font-weight: bold;
                    margin-bottom: 7px;
    
                }
    
                .input-values {
                    border: 1px solid #DADADA;
                    border-radius: 6px;
                    width: 100%;
                    height: 40px;
                    padding: 4px;
                    background-color: rgb(246, 246, 246);
                    outline: none;
    
                }
    
                .input-boxes {
                    border: 1px solid #DADADA;
                    border-radius: 6px;
                    // width: 50%;
                    height: 40px;
                    display: flex;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    
                    .input-color {
                        width: 15%;
                        height: 95%;
                        margin: 1px 12px 1px 8px;
                        outline: none;
                    }
    
                    .colores-name {
                        font-weight: bold;
                        text-align: center;
                        margin: auto;
                        margin-left: 1rem;
                    }
                }
            }
            .socialCard-div {
                // height: 100%;
                // width: 100%;
                border-radius: 12px;
                position: relative;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                .detail-social {
                    margin: 1rem;

                    .logo-business-name {
                        display: grid;
                        grid-template-columns: 20% 80%;

                        .business-logos {
                            height: 6rem;
                            width: 5rem;
                        }

                        .business-name {
                            font-size: 28px;
                            font-weight: bold;
                            margin-bottom: 0.5rem;
                            display: flex;
                            justify-content: center;
                            text-align: center;
                            align-items: center;
                        }

                    }

                    .headlines {
                        font-size: 25px;
                        font-weight: 500;
                        display: flex;
                        font-family: cursive;
                        justify-content: center;
                        text-align: center;


                    }

                    .msg {
                        display: flex;
                        width: 100%;
                        flex-wrap: wrap;
                        font-size: 17px;
                        font-weight: 400;
                        padding: 6px;
                        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
                        justify-content: center;
                        text-align: center;
                    }
                }
            }

        }

    }

    .downloadbtn {
        // width: 80%;
        display: flex;
        // justify-content: center;
        align-items: center;
        // height: 35px;
        margin-top: 1rem;
        // padding: 20px 0;
        // margin-bottom: 2rem;
    }

    .titles {
        // width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0293A8;
        color: white;
        border-radius: 5px;
        // height: 100%;
        padding: 10px 30px;
        // margin-right: -4.5rem;

    }
}


@media screen and (width <= 620px) {
    .socialCard-with-btns{
        padding: 30px;
    }
    .socialCard-with-btns .SocialCard-detail-container{
        display: flex;
        flex-direction: column;
    }
    .socialCard-with-btns .SocialCard-detail-container .half-input{
        width: unset;
    }
    .socialCard-with-btns .SocialCard-detail-container .socialCard_preview{
        height: unset;
    }
    .socialCard-with-btns{
        margin-bottom: 70px;
    }
}