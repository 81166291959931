.card-container{
    padding: 30px;
    background-color:rgb(246, 243, 243);
    .template{
        display: flex;
        justify-content: start;
        color: #6b6a6a;
        font-size: 18px;
        font-weight: bold;
    }
    .template-container{
        // margin-top: 2rem;
        // display: grid;
        // grid-template-columns: 32% 32% 32%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .cards{
            // margin: 1rem;
            // border: 2px solid #DADADA;
            .card{
            //    height: 13rem;
               width: 400px;
            }
        }
    }
}

@media screen and (width <= 620px) {
    .card-container{
        margin-bottom: 70px;
    }
}