.stamp-container{
    padding: 30px;
    background-color:rgb(246, 243, 243);
    height: calc(100vh - 100px);
    .stamp-template{
      display: flex;
      justify-content: start;
      font-size: 20px;
      font-weight: bold;
      color: #999999;
    }
    .template-stamp-container{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
        .Stamps1{
         width: 13rem;
         height: 13rem;
        //  margin: 1rem;
        .stamp1{
         width: 100%;
         height:100%;
        }
    }
    .Stamps2{
        width: 16rem;
        height: 10rem;
        // margin-top: 2rem;
        .stamp2{
            width: 100%;
            height: 100%;
        }
    }
    }
}