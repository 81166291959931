.terms-bg-con {
    height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    gap: 40px;
    .terms-msme-con{
        display: flex;
        justify-content: center;
        width: 60%;
        margin-bottom: 2.4em;
        .terms-cont{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 40px;
            .heading{
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 1rem;
            }
            .uploads-label{
                width: 100%;
                // height: 20em;
                overflow: hidden;
                .terms-msme-image{
                    width: 100%;
                    background-size: cover;
                }
                .empty-con{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .note{
        // margin-top: 2rem;
        font-weight: 500;
        color: #999999;
        font-size: 12px;
        .note-h{
            color: crimson;
            font-weight: 600;
            font-size: 14px;
        }
    }
    .cancelBtn{
            width: 160px;
            height: 35px;
            font-weight: 700;
            border: 2px solid #999999;
            border-radius: 8px;
            background-color: rgb(255, 255, 255);
            margin-right: 12px;
        }
        .saveBtn{
            width: 160px;
            height: 35px;
            font-weight: 700;
            border: 0px solid;
            border-radius: 8px;
            background-color: #0293A8;
            margin-left: 12px;
            color: aliceblue;
        }
}

.terms-bg-con .terms-msme-con{
    margin: unset;
    width: unset;
    gap: 50px;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.terms-bg-con .terms-msme-con .terms-cont{
    margin: unset;
    width: unset;
}
.terms-bg-con .terms-msme-con .terms-cont .heading{
    font-size: 20px !important;
}
@media screen and (width <= 620px) {
    .terms-bg-con{
        height: unset;
        margin-top: 30px;
        gap: 20px;
    }

    .terms-bg-con .terms-msme-con{
        margin: unset;
        width: unset;
        gap: 50px;
    }
    .terms-bg-con .terms-msme-con .terms-cont{
        flex-direction: column;
        margin: unset;
        width: unset;
        gap: 30px;
    }
    .terms-bg-con .terms-msme-con .terms-cont .heading{
        font-size: 16px !important;
    }
    .terms-bg-con .terms-msme-con .terms-cont .uploads-label{
        width: unset;
        height: unset;
    }
}