.note-bg-con{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .note-bg-img-con{
        position: absolute;
        top: -10vh;
        left: 5vw;
        z-index: -1;
        height: 90vh;
        width: 90vw;
        background-size: cover;
        background-position: center;
    }
    .note-header{
        width: 90%;
        height: 120px;
        .note-logo{
            height: 120px;
            background-size: cover;
            padding-top: 5vh;
        }
    }
    .notebody-con{
        height: 90vh;
        width: 90vw;
        background-size: cover;
        background-position: center;
        // background-image: url('https://res.cloudinary.com/aguruprasad/image/upload/v1686199375/cg_fvnw9s.png');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: visible;
        background-position: center;
        h1{
            font-size: 26px;
            font-weight: 600;
            color: #0293A8;
        }
        p{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 2em;
            color: #999999;
        }
        .buttons-con{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            button{
                width: 180px;
                height: 40px;
                margin: 1em 12px;
            }
            .trail{
                color: #0293A8;
                border: 2px solid #0293A8;
                border-radius: 12px;
            }
            .sub{
                color: #fff;
                border: 2px solid #0293A8;
                background-color: #0293A8;
                border-radius: 12px;
            }
        }
    }
    .footer-bottom-container{
        width: 90vw;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-top: 1px solid #545454;
    .footer-social-accounts{
        width: 8%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }.copyright{
        font-size: 16px;
        font-weight: 500;
    }
    .social-icon{
        font-size: 22px;
    }
}
}