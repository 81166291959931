.signup-Container{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-auto-rows: 100vh;
    background:  linear-gradient(164.58deg, #97cbd3 1% , #ffffff 100%);
    .left-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1em 0px 1em 1em;
        .auth-logo{
            width: 240px;
            background-size: cover;
        }
        .copy-right{
            color: #999999;
            font-size: 16px;
        }
    }
    .body-container{
        height: 100vh;
        width: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .email-container{
            .form-h{
                    font-size: 20px;
                    color: #999999;
                    font-weight: 300;
                }
            .email-input-container{
                display: flex;
                flex-direction: column;
                .form-h{
                    font-size: 20px;
                    color: #999999;
                    font-weight: 300;
                }
                .error-msg{
                    color: crimson;
                    font-size: 14px;
                    font-weight: 300;
                    margin-top: 12px;
                }
            }
            .login-h{
                font-size: 3vw;
                color: #0293A8;
                font-weight: 700;
            }
            .login-d{
                font-size: 1.5vw;
                color: #0293A8;
                font-weight: 300;
                margin-bottom: 2em;
            }
            .email-input{
                outline: none;
                border-bottom: 1.5px solid #E8E8E8;
                margin-top: 1rem;
                color: #999999;
                font-weight: 500;
            }
            .password-con{
                border-bottom: 1.5px solid #E8E8E8;
                margin-top: 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .pass-input{
                    outline: none;
                    color: #999999;
                    font-weight: 500;
                }
                .eye-icon{
                    font-size: 25px;
                    color: #505050;
                }
            }
            .rqs-btn{
                background-color: #0293A8;
                width: 60%;
                height: 40px;
                color: #fff;
                border: 0px solid;
                border-radius: 8px;
                align-self: center;
                margin-top: 2em;
            }
            .number-container{
                display: flex;
                flex-direction: column;
                .number-input-container{
                    .name-input{
                        outline: none;
                        border-bottom: 1.5px solid #E8E8E8;
                        margin-top: 1rem;
                        color: #999999;
                        font-weight: 500;
                        width: 100%;
                    }
                    .phone-number-con{
                        width: 100%;
                        height: 35px;
                        display: grid;
                        grid-template-columns: 15% 85%;
                        align-items: center;
                        margin-bottom: 1rem;
                        margin-top: 2rem;
                        .cuntry-code{
                            text-align: center;
                            background-color: #999999;
                            height: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fefff9;
                            border-radius: 6px 0px 0px 6px;
                        }
                        .phone-input{
                            width: 100%;
                            height: 35px;
                            outline: none;
                            padding: 5px;
                            border-radius: 0px 6px 6px 0px;
                            border: 0px solid;
                            color: #999999;
                            font-weight: 500;
                        }
                    }
                }
            }
            .otp-bg-container{
            flex-shrink: 0;
            width: 100%;
            display: grid;
            grid-template-columns: 90%;
            grid-template-rows: 90% 10%;
            justify-content: center;
            align-items: center;
            .otp-container{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .otp-heading{
                    color: #999999;
                    font-weight: 300;
                    width: 100%;
                    margin-bottom: 1rem !important;
                    text-align: left;
                    margin-top: 1em;
                    .number{
                        color: #97cbd3;
                        font-weight: 500;
                    }
                }
                .Otp{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1.5rem;
                    .otp-field{
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        border: 1px solid #999999;
                        border-radius: 6px;
                        margin-left: 5px;
                        margin-right: 5px;
                        outline: none;
                        color: #999999;
                        font-weight: 700;
                    }
                }
                .ver-btn{
                    width: 80%;
                    height: 40px;
                    border: 0px solid;
                    border-radius: 12px;
                    outline: none;
                    background-color: transparent;
                    background-image: linear-gradient(153.72deg, #ACDDDB, #F0FBCF 98.96%);
                    color: #0293A8;
                    font-weight: 700;
                    cursor: pointer;
                }
            }
        }
        }
        .or-container{
            display: flex;
            justify-self: center;
            align-items: center;
            margin-top: 1em;
            .hulf-line{
                width: 1em;
                color: #505050;
                border: 1px solid #505050;
                margin: 0px 8px;
            }
        }
            .buttons-con{
                display: flex;
                justify-content: center;
                align-items: center;
                .email-btn{
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 8px;
                    border: 1px solid #999999;
                    border-radius: 8px;
                    margin: 8px;
                    .icon{
                        height: 20px;
                        width: 20px;
                        margin-right: 12px;
                    }
                }
            }
        .already{
            color: #A1A1A1;
            font-size: 16px;
            font-weight: 300;
            margin-top: 1em;
            .sign-link{
                color: #8BB2B2;
                font-weight: 600;
            }
        }
    }
    .right-container{
        // width: 100%;
        // height: 100%;
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-end;
        // padding-bottom: 3em;
        // padding-left: 2em;
      
        position: absolute;
        gap: 50px;
        display: flex;
        bottom: 3%;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        width: 100%;
        .translater-bg{
            display: flex;
            align-items: center;
            padding-top: 20px;
            height: 22px;
            width: 170px;
            overflow: hidden;
        }
    }
}
.goog-te-gadget-simple{
    border: 1px solid black !important;
    background-color: #ffffff !important;
    color: #fff !important;
    display: flex !important;
    // width: max-content;
    // height: 22px;
    align-items: center;
    overflow: hidden;
    font-size: 14px !important;
    padding: 10px !important;
    border-radius: 20px;
    .VIpgJd-ZVi9od-xl07Ob-lTBxed{
        display: flex !important;
    }
}


.error-msg{
    color: crimson;
    font-size: 14px;
    font-weight: 300;
    margin-top: 12px;
}
@media screen and (width <= 920px) {
    .body-Container{
        grid-template-columns: 60% !important;
        .content-content{
            display: none !important;
        }
        .buttons-container{
            height: 35vh !important;
        }
    }
    .signup-Container{
        grid-template-columns: 20% 60% 20%;
        align-items: center;
        .body-container{
            padding: 10px !important;
            height: 80vh;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        .left-container .auth-logo{
            width: 150px !important;
            max-width: unset;
        }
        .email-container{
            .login-h{
                font-size: 22px !important;
            }
            .login-d{
                font-size: 16px !important;
            }
            .email-input{
                font-size: unset;
            }
            .password-con{
                display: flex !important;

            }
            .rqs-btn{
                width: 100% !important;
            }
        }

        .already{
            font-size: 14px;
        }

        .right-container{
            position: absolute;
            gap: 50px;
            display: flex;
            bottom: 3%;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
        }
        
    }
    
}


@media screen and (width <= 620px) {
    .nav-link{
        font-size: 14px !important;
    }
    .heading{
        font-size: 32px !important;
        line-height: 32px !important;
    }
    .signup-Container{
        grid-template-columns: 15% 70% 15%;
        align-items: center;
        .body-container{
            padding: 10px !important;
            height: 80vh;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            gap: 0 !important;
        }
        .left-container .auth-logo{
            width: 150px !important;
            max-width: unset;
        }
        .email-container{
            .login-h{
                font-size: 18px !important;
            }
            .login-d{
                font-size: 12px !important;
            }
            .email-input{
                font-size: unset;
            }
            .password-con{
                display: flex !important;

            }
            .rqs-btn{
                width: 100% !important;
            }
        }
        .buttons-con{
            flex-direction: column;
        }
        .already{
            font-size: 14px;
        }

        .right-container{
            position: absolute;
            gap: 50px;
            display: flex;
            bottom: 3%;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            .copy-right{
                font-size: 12px;
            }
        }
        
    }
    .body-Container{
        grid-template-columns: 90% !important;
        .buttons-container{
            height: 35vh !important;
        }

        
    }
    .goog-te-gadget-simple{
        font-size: 10px !important;
        padding: 5px !important;
    }
    
}

@media screen and (width <= 440px) {
    .otp-field{
        width: 30px !important;
        height: 30px !important;
    }
    .copy-right{
        font-size: 12px !important;
    }
    .signup-Container{
        grid-template-columns: 10% 80% 10%;
    }
}

