.card2-with-btns {
    padding: 20px;
    overflow-y: scroll;
    background-color:rgb(246, 243, 243);

    .card2-detail-container {
        display: grid;
        grid-template-columns: 50% 50%;

        .card2-detail {
            margin-top: 0.5rem;

            .logo-heading {
                font-size: 18px;
                color: #999999;
                font-weight: bold;
                margin-bottom: 7px;
            }

            .logo-container {
                border: 2px solid #DADADA;
                width: 55%;
                height: 9rem;
                margin-bottom: 7px;
                border-radius: 10px;

                .logo-img-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;

                    .add-logo-icon {
                        color: #0293A8;

                    }
                }
            }

            .half-input {
                display: flex;
                flex-direction: column;
                width: 90%;

                .label {
                    color: #999999;
                    font-size: 17px;
                    font-weight: bold;
                    margin-bottom: 7px;

                }

                .input-value {
                    border: 1px solid #DADADA;
                    border-radius: 6px;
                    width: 100%;
                    height: 40px;
                    padding: 4px;
                    background-color: rgb(246, 246, 246);
                    outline: none;

                }
            }
        }

        .card2_preview {
            height: 16rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-top: 15rem;
            width: 28rem;
            margin-right: 2rem;

            .card2_download {
                height: 100%;

                .preview-detail {
                    position: absolute;
                    top: .5rem;
                    width: 100%;
                    display: grid;
                    grid-template-columns: 80% 20%;

                    .p-details {
                        padding: 1rem;
                        font-size: 16px;
                        color: white;

                        .business-name-container {
                            width: 100%;
                            margin-bottom: 1rem;

                            .business-name {
                                font-size: 24px;
                                font-weight: 500;
                                display: flex;
                                justify-content: start;
                                align-items: center;
                            }
                        }

                        .each-detail {
                            display: grid;
                            width: 100%;
                            grid-template-columns: 10% 90%;
                            margin-bottom: 1rem;

                            .icons {
                                display: flex;
                                margin: auto;
                            }
                        }
                    }

                    .logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .logo-img {
                            margin-right: 6rem;
                            margin-top: 2rem;
                        }

                    }

                }
            }
        }
    }

    .btns {
        display: flex;
        width: 45%;
        margin-bottom: 1rem;
        margin: auto;

        .cancel-btn {
            margin-top: 15px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;

            .titles {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #0293A8;
                color: white;
                border-radius: 5px;
                padding: 0 3rem;
                height: 100%;
            }
        }

        .download-btn {
            margin-top: 15px;
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;

            .titles {
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #0293A8;
                color: white;
                border-radius: 5px;
                height: 100%;
            }
        }
    }
}


@media screen and (width <= 620px) {
    .card2-with-btns{
        display: flex;
        gap: 20px;
        flex-direction: column;
        margin-bottom: 70px;
    }
    .card2-with-btns .card2-detail-container{
        display: flex !important;
        flex-direction: column;
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        gap: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }

    .card2-with-btns .card2-detail-container .card2-detail{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }
    .card2-with-btns .card2-detail-container .card2-detail .logo-container,
    .card2-with-btns .card2-detail-container .card2-detail .half-input{
        width: 100% !important;
    }
    .card2-with-btns .card2-detail-container .card2-detail .half-input .input-value{
        background: white;
        padding: 10px !important;
    }
    .card2-with-btns .card2-detail-container .card2_preview{
        height: unset;
        width: unset;
        margin: unset;
    }
    .card2-with-btns .card2-detail-container .card2_preview .card2_download .preview-detail{
        display: flex !important;
        justify-content: space-between;
    }
    .card2-with-btns .card2-detail-container .card2_preview .card2_download .preview-detail .logo .logo-img{
        margin: unset;
        width: 100px;
    }
    .card2-with-btns .card2-detail-container .card2_preview .card2_download .preview-detail .p-details .business-name-container{
        margin: unset;

    }
    .card2-with-btns .card2-detail-container .card2_preview .card2_download .preview-detail .p-details .each-detail{
        margin: unset;
        
        gap: 10px;
    }
    .card2-with-btns .card2-detail-container .card2_preview .card2_download .preview-detail .logo{
        padding: 10px;
    }
    .card2-with-btns .btns{
        width: unset;
        margin: unset;
        gap: 20px;
        justify-content: center;
    }
    .card2-with-btns .btns .cancel-btn,
    .card2-with-btns .btns .download-btn{
        margin: unset;
        width: unset;
        height: unset;
    }
    .card2-with-btns .btns .cancel-btn .titles,
    .card2-with-btns .btns .download-btn .titles{
        width: unset;
        height: unset;
        padding: 5px 20px;
    }
}