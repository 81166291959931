/* Navbar styles and hover styles starts*/
.navbar {
  background-color: white;
  font-family: "Arbutus Slab";
  font-size: 14px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid rgb(191, 191, 191);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  z-index: 1;
  padding: 10px 100px;
}
.bg-button {
  background-color: #dc3545;
  border-radius: 5px;
  padding: 5px;
}
.e-heading {
  margin-left: 5%;
  margin-right: 5%;
  padding: 1rem;
  font-size: 24px;
  font-weight: 600;
}
.select-option {
  background-color: white;
  color: #0293a8;
}

.navbar {
  position: relative;
  transition: border-bottom-color 0.3s ease-in-out;
}

.tabs-con {
  width: 450px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 40px;

  .tab-item {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    font-family: "Hind", sans-serif;
    color: #363636;
  }
}

.tab-item:hover {
  color: #787878;
  transform: scale(1.03);
}

.navbar:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #004d00;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.navbar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  display: flex;
}

/* Navbar styles and hover styles start*/

.failure-con {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .failed-h {
    font-size: 22px;
    font-weight: 600;
  }

  .back-btn {
    width: 140px;
    border: 0px solid;
    border-radius: 12px;
    height: 35px;
    outline: none;
    color: #fff;
    font-weight: 600;
  }
}

.phone-bg-con {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .phone-number-con {
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding-top: 3em;
    padding-bottom: 3em;

    .signup-logo {
      width: 50%;
      background-size: cover;
    }

    .phone-lab {
      color: #999999;
      font-size: 14px;
      width: 90%;
      margin-top: 1em;
    }

    .seller-name-input {
      outline: none;
      border: 1px solid #999999;
      border-radius: 12px;
      width: 90%;
      padding: 8px;
      color: #4e4e4e;
      font-weight: 600;
      font-size: 18px;
    }

    .phone-number-input-div {
      border: 1px solid #999999;
      border-radius: 12px;
      width: 90%;
      display: grid;
      grid-template-columns: 40px calc(100% - 40px);
      padding: 2px;

      .reg-code {
        font-weight: 600;
        font-size: 18px;
      }

      .phone-number-input {
        padding: 6px;
        outline: none;
        color: #4e4e4e;
        font-weight: 600;
        font-size: 18px;
      }
    }

    .send-otp-btn {
      height: 40px;
      width: 160px;
      border-radius: 12px;
      background-color: #0293a8;
      color: #fff;
      font-weight: 600;
      margin-top: 1.5em;
    }
  }
}

.e-stores-bg-con {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 68vh;
  margin-left: 5%;
  margin-right: 5%;
  background-color: #d8eff4a3;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 2em;

  .e-h-btn-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .e-stores-h {
      font-size: 22px;
      font-weight: 500;
    }

    .e-add-btn {
      align-self: flex-end;
      background-color: #0293a8;
      color: #fff;
      border: 0px solid;
      border-radius: 12px;
      width: auto;
      padding: 10px 12px 10px 12px;
    }
  }

  .e-no-stors-con {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
    width: 100%;
    font-size: 22px;
    font-weight: 300;
  }

  .e-stors-con {
    width: 100%;
    height: 65vh;
    background-color: #f2fafb;
    margin-top: 2em;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;

    .e-stores-filter-section {
      width: 96%;
      height: 50px;
      padding: 6px;
      background-color: #fff;
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 15% 77% 8%;
      align-items: center;
      gap: 2%;

      .e-filter {
        background-color: #0293a8;
        color: #fff;
        outline: none;
        border: 0px solid;
        border-radius: 6px;
        font-weight: 600;
        height: 100%;
        padding: 2px;
      }

      .e-search-bar {
        outline: none;
        height: 100%;
        font-size: 18px;
        color: #4e4e4e;
      }

      .e-search-bar::placeholder {
        color: #56b7c4;
        font-size: 18px;
        margin: 0;
        padding: 0;
      }
    }

    .e-stores-header {
      width: 96%;
      display: grid;
      grid-template-columns: 12% 100px 22% 38% 12% calc(
          100% - (12% + 100px + 22% + 38% + 12%)
        );
      font-weight: 600;
      border-bottom: 2px solid;
      margin-top: 1rem;
    }

    .e-store-item {
      height: 60px;
      width: 96%;
      border: 1px solid #999999;
      border-radius: 12px;
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 100px 22% 38% 12% calc(
          100% - (100px + 22% + 38% + 12%)
        );
      align-items: center;
      padding: 6px;

      .e-new-store-logo {
        height: 48px;
        width: 48px;
        border-radius: 12px;
      }
    }

    .store-item {
      height: 60px;
      width: 96%;
      border: 1px solid #999999;
      border-radius: 12px;
      margin-top: 1rem;
      display: grid;
      // grid-template-columns: 12% 100px 22% 38% 12% calc(100% - (12% + 100px + 22% + 38% + 12%));
      grid-template-columns: 12% 100px 19% 40% 12% calc(
          100% - (12% + 100px + 22% + 38% + 12%)
        );
      align-items: center;
      padding: 6px;

      .new-store-logo {
        height: 48px;
        width: 48px;
        border-radius: 12px;
      }
    }
  }
}

/* Brand or logo (image) styles and hover styles start*/
.logo-img {
  margin-right: 20px;
  transition: transform 0.3s ease-in-out;
}

@keyframes border-animation {
  0% {
    border-color: #b3ffb3;
  }

  25% {
    border-color: #66ff66;
  }

  50% {
    border-color: #00cc00;
  }

  75% {
    border-color: #006600;
  }

  100% {
    border-color: #001a00;
  }
}

/* Brand or logo (image) styles and hover styles end*/

/* Navbar list (Home, About, Vision, Contact) styles and hover starts*/
.navbar-nav {
  list-style: none;
  padding: 0;
}

.nav-item {
  display: inline-block;
  position: relative;
}

.nav-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #000;
  transition: transform 0.3s ease-in-out;
}

.nav-link:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease-in-out;
}

@keyframes underline {
  0% {
    left: 0;
    width: 0;
  }

  25% {
    left: 0;
    width: 100%;
  }

  50% {
    left: 100%;
    width: 0;
  }

  75% {
    left: 0;
    width: 100%;
  }

  100% {
    left: 0;
    width: 100%;
  }
}

/* User profile or dropdown styles and hover styles starts */
.fa-regular {
  color: white;
  font-size: 20px;
  padding: 5px;
  background-color: #0293a8;
  border-color: #0293a8 !important;
}

.dropdown-menu.dropdown-menu-center {
  display: block;
  margin: 0;

  left: -20% !important;
  right: auto !important;
  transform: translateX(-50%) !important;
}

.dropdown-item {
  font-family: "Arbutus Slab";
  font-size: 14px;
  transition: all 0.3s ease;
}

.dropdown-item:hover {
  font-size: 15px;
  background-color: white;
}

/* Media query styles for 576 px size user window starts */
@media (max-width: 576px) {
  .nav-item {
    padding-left: 10%;
  }

  .dropdown {
    padding-left: 8%;
  }

  .dropdown-menu.dropdown-menu-center {
    left: 30% !important;
    right: 0 !important;
    transform: translateX(0) !important;
  }
}

/* Media query styles for 576 px size user window ends */

.e-user-details {
  // border: 1px solid red;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.e-user-left,
.e-user-right {
  // border: 1px solid black;
  margin: 20px;
}

.e-user-right {
  margin-top: 40px;
}

.e-user-left {
  display: flex;
  flex-direction: column;
}

.e-user-upload-img {
  border: 0;
  background: #0293a8;
  border-radius: 5px;
  font-family: "Hind";
  font-size: 16px;
  color: #ffffff;
  padding: 5px 10px;
  text-align: center;
}

.e-user-left img {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  border-radius: 50%;
}

.e-user {
  // border: 1px solid blue;
  line-height: 1;
}

.e-user-text {
  border: 1px solid #0293a8;
  border-radius: 5px;
  font-size: 16px;
  width: 300px;
  padding: 10px 15px;
}

.e-user span {
  background-color: #ffffff;
  // border: 1px solid green;
  position: relative;
  top: -46px;
  left: 15px;
  font-size: 14px;
  color: #0293a8;
  // transform: translate(15px, -15px);
}

.e-user-dashboard {
  display: flex;
  flex-direction: column;
  background-color: #d8eff4;
  margin: 10px 150px;
  padding: 20px;
  border-radius: 5px;
}

.e-user-dashboard-first {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.e-user-dashboard-first div {
  font-size: 16px;
  color: #0293a8;
}

.e-user-dashboard-second {
  margin-top: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.e-user-filter {
  background-color: #0293a8;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff;
  width: 10%;
}

.filter {
  background-color: #0293a8;
  color: #ffffff;
  border-radius: 3px;
  font-size: 16px;
  outline: none;
  border: none;
  padding-left: 2px;
}

.e-user-search {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.e-user-search input {
  outline: none;
  border: none;
  padding-left: 10px;
}

.e-user-search input::placeholder {
  color: #0293a8;
}

.e-user-search i {
  color: #d9d9d9;
  cursor: pointer;
  padding-right: 5px;
}

.e-user-table-headings {
  // border: 1px solid red;
  // width: 1046px;
  background-color: #ffffff;
  margin-top: 10px;
  padding: 5px;
  display: flex;
  // justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  border-radius: 3px;
  align-items: center;
}

.e-user-table-headings div {
  width: 16.67%;
}

.e-user-table-content {
  background-color: #ffffff;
  padding: 7px 5px;
  display: flex;
  // justify-content: space-between;
  margin-top: 5px;
  border-radius: 3px;
}

.e-user-table-content div {
  font-size: 14px;
  width: 16.67%;
}

.e-user-status-paid {
  color: green;
  background-color: rgb(187, 251, 187);
  border-radius: 5px;
  padding: 2px 40px;
}

.e-user-status-unpaid {
  color: red;
  background-color: rgb(237, 133, 133);
  border-radius: 5px;
  padding: 2px 30px;
}

//invoice css

.pdf-container {
  padding-top: 3em;
  padding-bottom: 3em;
}

.invoiceHeading,
.invoiceSubHeading {
  text-align: center;
}

.invoiceHeading {
  font-size: 25px;
  font-weight: 400;
}

.invoiceFirstBox {
  display: flex;
  justify-content: center;
}

.invoice-left,
.invoice-right {
  padding: 20px;
  margin: 10px;
  border: 1px solid black;
}

.invoice-left {
  width: 30%;
}

.invoice-left img {
  width: 100%;
}

.invoice-right {
  width: 30%;
}

.invoiceSecondBox {
  display: flex;
  justify-content: center;
  // padding-left: 246px;
}

.invoiceSecondBox-content {
  // border: 1px solid black;
  width: 61%;
}

.invoice-dashboard {
  display: flex;
  flex-direction: column;
  // background-color: #D8EFF4;
  margin: 10px 100px;
  padding: 20px;
  border-radius: 5px;
}

.invoice-content {
  border: 1px solid black;
  width: 80%;
  background-color: #ffffff;
  padding: 7px 5px;
  display: grid;
  grid-template-columns: 8% 20% 20% 20% 20% 12%;
  border-radius: 0px 0px 3px 3px;
}

.invoice-content div {
  font-size: 14px;
}

.invoiceheadingouter {
  display: flex;
  justify-content: center;
}

.invoice-headings {
  width: 80%;
  border: 1px solid black;
  color: #ffffff;
  background-color: #0293a8;
  margin-top: 10px;
  padding: 10px 5px;
  display: grid;
  grid-template-columns: 8% 20% 20% 20% 20% 12%;
  font-weight: 500;
  font-size: 16px;
  border-radius: 3px 3px 0px 0px;
  border-bottom: 0px solid;
  align-items: center;
}

.seconLastBox {
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.secondLastBoxContent {
  width: 62%;
}

.invoiceLastBox {
  display: flex;
  justify-content: center;
}

.invoiceLastBoxbtn {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  width: 61%;
}

.invoiceBtn {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: white;
}

.viewbtn {
  background-color: rgb(48, 239, 102);
}

.login-btn {
  background-color: #0293a8;
}

.printbtn {
  background-color: rgb(31, 191, 244);
}

.fa-print {
  padding-right: 4px;
}

.closebtn {
  background-color: rgb(255, 58, 58);
}

.e-add-btn:hover {
  background-color: #83c7d1 !important;
  color: #2e2e2e !important;
  border: 2px solid #0293a8 !important;
  font-weight: 600;
}

.emitraBox {
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    margin-left: 10px;
  }
}
