.stamp-btn-container{
    padding: 3rem;
    background-color:rgb(246, 243, 243);
.Stamp2-container{
   display: grid;
    grid-template-columns: 60% 40%;
    margin-top:7rem;
    .stamp-input {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin: auto;

        .label {
            color: #999999;
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 7px;

        }

        .input-value {
            border: 1px solid #DADADA;
            border-radius: 6px;
            width: 100%;
            height: 40px;
            padding: 4px;
            background-color: rgb(246, 246, 246);
            outline: none;

        }
    }
    .stamp2-img{
        width: 80%;
        margin: auto;
        position: relative;
        .business-stamp{
            position: absolute;
            top: 4rem;
            width: 100%;
        .business-stamp-name{
            text-transform: uppercase;
            font-size: 30px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
}

.download-stamp-btn {
    margin-top: 3rem;
    width: 100%;
    height: 35px;

    .titles {
        margin: auto;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0293A8;
        color: white;
        border-radius: 5px;
        height: 100%;
    }
}
}


@media screen and (width <= 620px) {
    .stamp-btn-container{
        padding: 2rem !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .stamp-btn-container .Stamp1-container{
        display: flex !important;
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }
    .stamp-btn-container .Stamp1-container .stamp-input{
        margin: unset;
        width: unset;

    }
    .stamp-btn-container .Stamp1-container .stamp-input .input-value{
        background-color: white !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .stamp-btn-container .Stamp1-container .stamp1-img{
        height: unset;
        margin: unset;
        position: unset;
        width: unset;
    }
    .stamp-btn-container .download-stamp-btn .titles{
        height: unset;
        width: unset;
        margin: unset;
        padding: 5px 20px;
    }
    .stamp-btn-container .download-stamp-btn{
        margin: unset;
        width: unset;
        height: unset;
    }
    .stamp-btn-container .Stamp1-container{
        margin: unset;
    }

    .stamp-btn-container .Stamp2-container{
        display: flex !important;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin: unset;
    }
    .stamp-btn-container .Stamp2-container .stamp2-img,
    .stamp-btn-container .Stamp2-container .stamp-input{
        width: unset;
        margin: unset;
    }
}