.product-con{
    display: flex;
    // height: 100%;
    // width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid #0293A8;
    border-radius: 10px;
    margin-bottom: 1rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    padding: 8px;
    .product-img-con{
        width: 230px;
        height: 200px;
        flex-shrink: 0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
    }
    .product-name-price{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width:90%;
        margin-top: 10px;
        margin-bottom: 10px;
        justify-self: flex-start;
        .prod-name{
            color: #0293A8;
            font-weight: bold;
            font-size: 17px;
        }
    }
    .category{
        width: 90%;
        margin-bottom: 10px;
        color: #999999;
    }
    .sizes{
        display: flex;
        width: 90%;
        border: 1px solid #0293A8;
        border-radius: 6px;
        padding: 3px;
        display: flex;
        align-items: center;
        overflow: hidden;
        overflow-x: scroll;
        flex-shrink: 0;
        .sizes-text{
            margin-right: 18px;
            margin-left: 8px;
            font-weight: 500;
            color: #999999;
        }
        .size{
            font-weight: 600;
            color: #999999;
            padding: 6px;
            flex-shrink: 0;
        }
        .active-size-btn{
            font-weight: 700;
            border: 1px solid #999999;
            color: #282828;
            border-radius: 6px;
            line-height: 12px;
        }
    }
    .colors{
        display: flex;
        width: 90%;
        border: 1px solid #0293A8;
        border-radius: 6px;
        padding: 3px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        .colors-text{
            margin-right: 10px;
            margin-left: 8px;
            font-weight: 500;
            color: #999999;
        }
        .color{
            width: 18px;
            height: 18px;
            margin-right: 6px;
            border-radius: 4px;
        }
        .active-color-btn{
            width: 24px;
            height: 24px;
        }
    }
    .count-controlls{
        width: 60%;
        display: grid;
        grid-template-columns: 30% 40% 30%;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        border: 1px solid #999999;
        border-radius: 10px;
        background-color: #0293A8;
        color: #fff;
        font-weight: 600;
        .controll-btn{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            height: 30px;
            border-radius: 8px;
        }
    }
    .add-btn{
        width: 60%;
        border: 1px solid #0293A8;
        border-radius: 7px;
        color: #0293A8;
    }
}