.contactus-bg{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .contactus{
        width: 70%;
        min-height: 60vh;
        border: 0px solid;
        border-radius: 18px;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        background-color: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        .label{
            color: #999999;
        }
        .email, .name{
            padding: 8px;
            margin-bottom: 1rem;
            outline: none;
            border-radius: 12px;
            border: 1px solid #999999;
            margin-top: 8px;
            color: #3a3a3a;
            font-weight: 700;
        }
        .message{
            padding: 8px;
            margin-bottom: 1rem;
            outline: none;
            border-radius: 12px;
            border: 1px solid #999999;
            margin-top: 8px;
            color: #3a3a3a;
            font-weight: 500;
        }
        .error-msg{
            color: crimson;
            font-size: 13px;
            font-weight: 500;
        }
    }
    .send-btn{
        width: 180px;
        height: 40px;
        border: 0px solid;
        border-radius: 12px;
        outline: none;
        background-color: #0293A8;
        color: #dafaff;
        align-self: center;
        font-weight: 700;
    }
}