.card3-with-btns {
    padding: 0 3rem 0 3rem;
    overflow-y: scroll;
    overflow-y: scroll;
    background-color:rgb(246, 243, 243);

    .card3-detail-container {
        display: grid;
        grid-template-columns: 50% 50%;

        .card3-detail {
            margin-top: 0.5rem;

            .logo-heading {
                font-size: 18px;
                color: #999999;
                font-weight: bold;
                margin-bottom: 7px;
            }

            .logo-container {
                border: 2px solid #DADADA;
                width: 55%;
                height: 9rem;
                margin-bottom: 7px;
                border-radius: 10px;

                .logo-img-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;

                    .add-logo-icon {
                        color: #0293A8;

                    }
                }
            }

            .half-input {
                display: flex;
                flex-direction: column;
                width: 90%;

                .label {
                    color: #999999;
                    font-size: 17px;
                    font-weight: bold;
                    margin-bottom: 7px;

                }

                .input-value {
                    border: 1px solid #DADADA;
                    border-radius: 6px;
                    width: 100%;
                    height: 40px;
                    padding: 4px;
                    background-color: rgb(246, 246, 246);
                    outline: none;

                }
            }
        }

        .card3_preview {
            height: 16rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15rem;
            width: 28rem;
            margin-right: 2rem;

            .card3_download {
                height: 100%;
                width: 100%;
                position: relative;

                .preview-detail3 {
                    width: 100%;
                    position: absolute;
                    top: 0.5rem;

                    .business-name-logo3 {
                        width: 100%;
                        padding: 10px;
                        display: grid;
                        grid-template-columns: 25% 75%;

                        .logos3 {
                            border: none;
                            height: 5rem;
                            width: 5rem;
                        }

                        .business-name3 {
                            width: 100%;
                            font-size: 25px;
                            font-weight: bold;
                            font-family: cursive;
                            color: white;
                        }
                    }

                    .p-details3 {
                        width: 75%;
                        margin-left: 8rem;
                        margin-top: -3rem;
                        color: white;
                        font-size: 15px;
                        font-family: cursive;
                        font-weight: 400;

                        .phone-number3 {
                            margin-bottom: 3px;
                        }

                        .web3 {
                            margin-bottom: 3px;
                        }

                        .email3 {
                            margin-bottom: 3px;
                        }

                        .add3 {
                            margin-bottom: 3px;
                        }
                    }
                }
            }
        }

    }

    .btns {
        display: flex;
        width: 45%;
        margin-bottom: 1rem;
        margin: auto;

        .cancel-btn {
            margin-top: 15px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            .titles {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #0293A8;
                color: white;
                border-radius: 5px;
                padding: 0 3rem;
                height: 100%;
            }
        }

        .download-btn {
            margin-top: 15px;
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;

            .titles {
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #0293A8;
                color: white;
                border-radius: 5px;
                height: 100%;
            }
        }
    }
}

@media screen and (width <= 620px) {
    .card3-with-btns{
        display: flex;
        gap: 20px;
        padding: 20px;
        flex-direction: column;
        margin-bottom: 70px;
    }

    .card3-with-btns .card3-detail-container{
        display: flex !important;
        flex-direction: column;
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        gap: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    }
    .card3-with-btns .card3-detail-container .card3-detail{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .card3-with-btns .card3-detail-container .card3-detail .logo-container{
        width: 100%;
    }
    .card3-with-btns .card3-detail-container .card3-detail .half-input{
        width: 100%;
    }
    .card3-with-btns .card3-detail-container .card3-detail .half-input .label{
        margin: unset;
    }
    .card3-with-btns .card3-detail-container .card3-detail .half-input .input-value{
        padding: 10px;
        background-color: white;
    }
    .card3-with-btns .card3-detail-container .card3_preview{
        height: unset;
        width: unset;
        margin: unset;
    }
    .card3-with-btns .card3-detail-container .card3_preview .card3_download .preview-detail3 .business-name-logo3{
        display: flex !important;
        gap: 20px;
    }
    .card3-with-btns .card3-detail-container .card3_preview .card3_download .preview-detail3{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .card3-with-btns .card3-detail-container .card3_preview .card3_download .preview-detail3 .p-details3{
        width: unset;
        margin: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .card3-with-btns .card3-detail-container .card3_preview .card3_download .preview-detail3 .business-name-logo3 .business-name3{
        width: unset;
    }
    .card3-with-btns .card3-detail-container .card3_preview .card3_download .preview-detail3 .business-name-logo3 .logos3{
        height: 2rem;
        width: 4rem;
    }
    .card3-with-btns .card3-detail-container .card3_preview .card3_download .preview-detail3 .p-details3 .add3,.phone-number3,.web3,.email3{
        margin: unset !important;
    }
    .card3-with-btns .btns{
        width: unset;
        margin: unset;
        justify-content: center;
        gap: 20px;
    }
    .card3-with-btns .btns .cancel-btn,.card3-with-btns .btns .download-btn{
        width: unset;
        margin: unset;
        height: unset;
    }
    .card3-with-btns .btns .cancel-btn .titles,.card3-with-btns .btns .download-btn .titles{
        height: unset;
        width: unset;
        padding: 5px 20px;
    }
}