.subscription{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 90%;
    gap: 30px;
    .subscription-h{
        width: 90%;
        font-size: 20px;
        color: #363636;
    }
    .subscription-plan{
        // width: 90%;
        // min-height: 40vh;
        // display: grid;
        // gap: 5%;
        // grid-template-columns: 50% 50%;
        display: flex;
        align-items: center;
        gap: 30px;
        // align-items: center;
        .plan-data{
            width: 400px;
            background-size: cover;
            // margin-top: 3rem;
            padding: 18px;

            border: 1px solid #0293A8;
            border-radius: 12px;
        }
        .congratulations-con{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 3rem;
            .congratulations-text{
                font-size: 22px;
                font-weight: 300;
                line-height: 48px;
            }
            .congratulations{
                font-size: 26px;
                font-weight: 500;
                line-height: 48px;
            }
        }
        .plans{
            // width: 100%;
            display: flex;
            align-items: center;
            gap: 30px;
            // flex-wrap: wrap;
            // justify-content: center;
            // margin-top: 3rem;
            .plan{
                // width: 45%;
                // margin: 2.5%;
                display: flex;
                flex-direction: column;
                // align-items: center;
                // justify-content: center;
                .plan-name{
                    text-align: center;
                    width: 100%;
                    padding: 12px;
                    border: 1px solid rgba(2, 147, 168, 0.15);
                    border-radius: 12px 12px 0px 0px;
                    font-size: 16px;
                    font-weight: 600;
                    background-image: linear-gradient(180deg, #0293A8 0%, #EDFAFC 111.76%, rgba(217, 217, 217, 0) 111.77%);
                    color: #EDFAFC;
                }
                .plan-details{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid rgba(2, 147, 168, 0.15);
                    border-radius: 0px 0px 12px 12px;
                    width: 100%;
                    padding: 12px;
                    background-color: #1dc9e325;
                    h1{
                        font-size: 20px;
                        line-height: 22px;
                        font-weight: 600;
                        color: #0293A8;
                        margin-bottom: 6px;
                    }
                    p{
                        color: #999999;
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 12px;
                    }
                    button{
                        // width: 80%;
                        // height: 30px;
                        padding: 5px 20px;
                        border: 0px solid;
                        border-radius: 10px;
                        background-color: #0293A8;
                        color: #fff;
                    }
                }
            }
        }
        // .plan-price{
        //     background-image: linear-gradient(101.05deg, rgba(2, 147, 168, 0.47) -1.04%, rgba(97, 198, 156, 0.286406) 38.9%, rgba(245, 255, 136, 0.44) 101.2%);;
        //     text-align: center;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     height: 50px;
        //     width: 240px;
        //     border-radius: 18px;
        //     border: 0px solid;
        //     .plan{
        //         font-size: 18px;
        //         font-weight: 500;
        //         .price{
        //             font-weight: 700;
        //             margin-left: 12px;
        //             font-size: 20px;
        //         }
        //     }
        // }
    }
    .sub-btn{
        color: aliceblue;
        background-color: #0293A8;
        // width: 140px;
        // height: 35px;
        border-radius: 8px;
        // margin-top: 2rem;
        padding: 5px 20px;
    }
    .payment-page{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 100px);
        overflow: hidden;
        .sub-payment-h{
            font-size: 28px;
            color: #0293A8;
            font-weight: 300;
        }
        .sub-pay-img{
            width: 100%;
            object-fit: contain;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
        .pay-now-btn{
            background-color:#0293A8;
            color: #fff;
            font-size: 18px;
            width: 160px;
            height: 35px;
            border-radius: 8px;
            border: 0px solid;
        }
    }
    .success-view{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .success-h{
            font-size: 28px;
            color: #0293A8;
            margin-bottom: 1rem;
        }
        .success-p{
            font-size: 16px;
            font-weight: 600;
            color: #999999;
            width: 30%;
            margin-bottom: 2em;
            text-align: center;
        }
        .success-img{
            width: 40%;
            background-size: cover;
        }
    }
}

.pay-title{
    font-size: 14px;
    color: #999999;
}

.failure-view{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .failed-h{
        font-size: 22px;
        color: #0293A8;
        font-weight: 500;
    }
    .failed-p{
        font-size: 14px;
        color: #363636;
        font-weight: 300;
        margin-top: 10px;
    }
    .f-back-btn{
        width: 160px;
        height: 34px;
        border: 0px solid;
        outline: none!important;
        border-radius: 8px;
        background-color: #0293A8;
        color: #fff;
        font-weight: 500;
        margin-top: 1rem;
    }
}

@media screen and (width <= 620px) {
    .profile-con, .profile-con .editdiv{
        flex-direction: column;
        gap: 20px;
    }
    .subscription .subscription-plan{
        flex-direction: column;
        align-items: center;
    }
    .subscription .subscription-plan .plan-data{
        width: 300px !important;
    }
}