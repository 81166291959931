.bill-item{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 0px 8px 0px;
    .id{
        color: #0293A8;
    }
    .view-more{
        color: #00AB41;
    }
}

.bill-bg-container{
        background-color: rgb(246, 243, 243);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 100vh;
        .invoice-background-container{
            background-color: #fff;
            border-radius: 12px;
            
            padding: 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                
            .inv-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #0293A8;
                line-height: 14px;
                padding-bottom: 12px;
                .inv-heading{
                    font-size: 28px;
                    line-height: 28px;
                }
            }
            .inv-h-line{
                color: #0293A8;
                border: 1px solid #0293A8;
                margin-top: 4px;
            }
            .inv-top-sec{
                margin-top: 1em;
                display: flex;
                justify-content: space-between;
                
                .inv-top-left{
                    width: 30%;
                    color: #999999;
                }
                .inv-top-right{
                    width: 50%;
                    color: #636363;
                    text-align: right;
                }
            }
            .inv-delivery-address{
                
                margin-bottom: 2em;
                .delivery-h{
                    color: #000000;
                    font-size: 22px;
                }
                .delivery-address{
                    color: #999999;
                }
            }
            .inv-bill{
                margin-bottom: 1rem;
                .inv-bill-hed{
                    background-color: #666666;
                    display: flex;
                    justify-content: space-between;
                    text-align: center;
                    color: #F9FBFC;
                    p{
                        padding: 10px;
                    }
                }
                .inv-bill-body{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                    color: #363636;
                }
            }
            .inv-bill-bottom-con{
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                border-top: 2px solid #D1D1D1;
                color: #363636;
                .Thanks-text{
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                }
                .charges{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    line-height: 32px;
                    font-size: 14px;
                }
                .charges-prices{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    line-height: 32px;
                    font-size: 14px;
                }
            }
            .bottom-line{
                color: #0293A8;
                border: 1px solid #0293A8;
                margin-bottom: 1em;
            }
            .total-amount-con{
                display: flex;
                justify-content: flex-end;
                gap: 30px;

                .toatal,.total-amount{
                    font-weight: 700;
                }
                
            }
        }
        .buttons-con{
            display: flex;
            flex-direction: column;
            gap: 20px;
            .cancel-btn{
                
                border: 1px solid #999999;
                border-radius: 8px;
                background-color: white;
                font-weight: 600;
                
                padding: 5px 20px;
            }
            .download-btn{
                
                border: 1px solid #0293A8;
                border-radius: 8px;
            
                font-weight: 600;
                background-color: #0293A8;
                color: #F9FBFC;
                padding: 5px 20px;
                
            }
        }
    }

    @media screen and (width <= 620px) {
        .bill-bg-container{
            flex-direction: column;
            margin-bottom: 100px;
            height: unset !important;
        }
        .bill-bg-container .buttons-con{
            flex-direction: row;
        }
    }