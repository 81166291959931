.admin-bg-con{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    .stores-bg-con{
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 80%;
        .h-btn-con{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 90%;
            .stores-h{
                font-size: 22px;
                font-weight: 500;
            }
            .add-btn{
                align-self: flex-end;
                background-color: #0293A8;
                color: #fff;
                border: 0px solid;
                border-radius: 12px;
                width: 160px;
                height: 40px;
            }
        }
        .no-stors-con{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 22px;
            font-weight: 300;
        }
        .stors-con{
            width: 100%;
            height: 65vh;
            background-color: #F2FAFB;
            margin-top: 2em;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            overflow-y: scroll;
            .stores-filter-section{
                width: 96%;
                height: 50px;
                padding: 6px;
                background-color: #fff;
                margin-top: 1rem;
                display: grid;
                grid-template-columns: 15% 77% 8%;
                align-items: center;
                gap: 2%;
                .filter{
                    background-color: #0293A8;
                    color: #fff;
                    outline: none;
                    border: 0px solid;
                    border-radius: 6px;
                    font-weight: 600;
                    height: 100%;
                }
                .search-bar{
                    outline: none;
                    height: 100%;
                    font-size: 18px;
                    color: #4e4e4e;
                }
                .search-bar::placeholder{
                    color: #56B7C4;
                    font-size: 18px;
                    margin: 0;
                    padding: 0;
                }
            }
            .stores-header{
                width: 96%;
                display: grid;
                grid-template-columns: 100px 22% 38% 12% calc(100% - (100px + 22% + 38% + 12%));
                font-weight: 600;
                border-bottom: 2px solid;
                margin-top: 1rem;
            }
            .store-item{
                height: 60px;
                width: 96%;
                border: 1px solid #999999;
                border-radius: 12px;
                margin-top: 1rem;
                display: grid;
                grid-template-columns: 100px 22% 38% 12% calc(100% - (100px + 22% + 38% + 12%));
                align-items: center;
                padding: 6px;
                .new-store-logo{
                    height: 48px;
                    width: 48px;
                    border-radius: 12px;
                }
            }
        }
    }
    .phone-bg-con{
        display: flex;
        justify-content: center;
        align-items: center;
        .phone-number-con{
            background-color: #fff;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: min-content;
            gap: 10px;
            padding: 20px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

            .signup-logo{

                width: 200px;
                background-size: cover;
            }
            .phone-lab{
                color: #999999;
                font-size: 14px;
                margin: 0;
            }
            .seller-name-input{
                outline: none;
                border: 1px solid #999999;
                border-radius: 12px;
                padding-left: 5px;
                color: #4e4e4e;
                font-weight: 600;
                font-size: 16px;
            }
            .phone-number-input-div{
                border: 1px solid #999999;
                border-radius: 12px;    
                .reg-code{
                    font-weight: 600;
                    font-size: 18px;
                }
                .phone-number-input{
                    padding: 6px;
                    outline: none;
                    color: #4e4e4e;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
            .send-otp-btn{
                padding: 10px;
                border-radius: 12px;
                background-color: #0293A8;
                color: #fff;
                font-weight: 600;
                margin-top: 10px;
            }
        }
    }
    .phoneotp-bg-con{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}
// phone number verification
.phoneotp-bg-con{
    flex-shrink: 0;
    width: 100%;
    // display: grid;
    grid-template-columns: 90%;
    grid-template-rows: 90% 10%;
    justify-content: center;
    align-items: center;
    .phone-otp-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .otp-heading{
            color: #999999;
            font-weight: 300;
            width: 100%;
            margin-bottom: 1rem !important;
            text-align: left;
            margin-top: 1em;
            .number{
                color: #97cbd3;
                font-weight: 500;
            }
        }
        .Otp{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1.5rem;
            .otp-field{
                width: 40px;
                height: 40px;
                text-align: center;
                border: 1px solid #999999;
                border-radius: 6px;
                margin-left: 5px;
                margin-right: 5px;
                outline: none;
                color: #999999;
                font-weight: 700;
            }
        }
        .ver-btn{
            width: 80%;
            height: 40px;
            border: 0px solid;
            border-radius: 12px;
            outline: none;
            background-color: transparent;
            background-image: linear-gradient(153.72deg, #ACDDDB, #F0FBCF 98.96%);
            color: #0293A8;
            font-weight: 700;
            cursor: pointer;
        }
    }
}

// adding the domain of the website

.sdomain-input{
    height: 20vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 20vh;
}
.domain-input {
    margin: 0;
    display: flex;
    border: 2px solid #DADADA;
    border-radius: 15px;
    // width: 40%;
    padding: 7px;
    font-size: 20px;
    color: #999999;
    font-weight: 600;

    .full {
        width: 85%;
        height: 35px;
        outline: none;
        // background-color: #F5F5F5;
        padding-left: 5px;
    }

    .server-name {
        margin: auto;
        color: #0293A8;


    }

}
.screate-domain{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
// shop details
.oshop-details{
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding-top: 3em;
    padding-bottom: 3em;
    border-radius: 12px;
}
.shop-details {
    display: flex;
    justify-content: center;
    font-size: 26px;
     
}

.shop-sub-heading {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #7c7878cd;
   
   
}

.business-input {
    // width: 50%;
    // margin: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 7px;
    font-size: 20px;
    color: #999999;
    .title{
        color: #999999;
        font-size: 14px;
    }

    .full {
        border: 2px solid #DADADA;
        border-radius: 8px;
        // width: 100%;
        // height: 40px;
        padding: 10px;
        font-size: 16px;
        outline: none;

    }
}

.error-msg{
    color: crimson;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
}

.shopDetails-btn{
    width: 100%;
}

// add first product

.p-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    padding-top: 3em;
    padding-bottom: 3em;
    border-radius: 12px;
    background-color: #fff;
}
.product-heading {
    display: flex;
    justify-content: center;
    font-size: 28px;
}

.product-sub-heading {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    color: #7c7878cd;
}

.first-product-container{ 
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    .image-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
        text-align: center;
        margin-top: 12px;
        .without-img-container{
            display: flex;
            flex-direction: column;
            border: 2px solid #DADADA;
            height: 13rem;
            width: 11rem;
            border-radius: 8px 8px 8px 8px;
            font-weight: 600;
            align-items: center;
            justify-content: center;


        }
        .uploaded-img{
         height: 10rem;
         width: 11rem;
        }
        .upload-button{
            height: 3rem;
            width: 11rem;
            font-weight: bold;
            border-radius: 0 0 8px 8px;
            border-bottom: none;;
            background-color: #0293A8;
            .click-upload-btn{
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: center;
               width: 100%;
               height: 3rem;
               
               cursor: pointer;
               .text-container{
                display: flex;
                font-size: 20px;
                color: white;
                font-size: 17px;
               }
            }
        }

    }
    .text-inputs{
        // width: 35%;
        // margin-left: 2rem;
        .product-input{
            width: 100%;
           display: flex;
           flex-direction: column;
           margin-top: 5px;
          .title{
            color: #999999;
            font-size: 14px;
            font-weight: 600;
          }
          .product-full{
            border: 2px solid #DADADA;
           height: 35px;
           border-radius: 5px;
           outline: none;
           padding: 5px;
           color: #999999;
           font-weight: 500;
          }
        }
        
    }
    .form-two-line-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        color: #999999;
        .price-container{
         display: flex;
         flex-direction: column;
         width: 46%;
         .title{
            color: #999999;
            font-size: 14px;
            font-weight: 600;
          }
        .hulf-Width-input {
            width: 100%;
            height: 35px;
            border-radius: 5px;
            border: 2px solid #DADADA;
            outline: none;
            padding: 5px;
            font-weight: 500;
            color: #999999;
            font-size: 14px;
        }
        }
    }


}
.bottom-btn-container,.ver-btn {
    background: #0293A8;
    border: 1px solid #0293A8;
    // padding-top: 5px;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 18px;
    outline: none;
    text-align: center;
    // margin-top: 1rem;
    font-weight: 600;
    letter-spacing: 0.03rem;
    // margin: auto;
    color: #ffff;
    // margin-top: 2rem;
    // width: 140px;
    // height: 40px;
    cursor: pointer;
}


.error{
    text-align: center;
    color: crimson;
    font-size: 14px;
    font-weight: 600;
    margin-top: 8px;
}

.page-controllers{
    display: flex;
    .back-btn, .next-btn{
        background-color: #0293A8;
        height: 32px;
        width: 32px;
        border-radius: 6px;
        border: 0px solid;
        color: rgb(255, 255, 255);
        // margin-left: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 300;
        // margin-top: 1em;
    }

    .next-btn{
        margin-left: 1rem;
    }

    .back-btn:hover, .next-btn:hover{
        background-color: #68ebff;
        color: black;
    }

}


.skip-btn{
    position: fixed;
    top: 90%;
    right: 2em;
    background-color: #0293A8;
    color: white;
    width: 120px;
    height: 35px;
    border-radius: 16px;
}
.skip-btn:hover{
    background-color: #7acbd7;
    color: black;
}

.footer-bottom-container{

        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-top: 1px solid #545454;
        
    .footer-social-accounts{
        
        display: flex;
        // justify-content: space-around;
        align-items: center;
        gap: 10px;
    }.copyright{
        font-size: 16px;
        font-weight: 500;
    }
    .social-icon{
        font-size: 22px;
    }
}

// payment
.pay-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
}
.payment-heading {
    display: flex;
    justify-content: center;
    font-size: 28px;
}

.payment-sub-heading {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    color: #7c7878cd;
}
.pay-cod{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    
    .payment-cod-container {
        display: flex;
        // width: 60%;
        gap: 30px;
        // justify-content: space-between;
        // align-items: center;
    
        .payment-container {
            .qr-container {
                border: 2px solid #DADADA;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
    
                .qr-code {
                    width: 15rem;
                    height: 16rem;
                    padding: 2px;
                }
    
                .without-qr {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 66%;
    
                    .without-upload {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        // width: 100%;
                        // height: 16rem;
                        cursor: pointer;
    
                        .icons {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            color: #DADADA;
    
                            .texts {
                                margin-bottom: 0.5rem;
                                font-size: 14px;
                                color: #DADADA;
                            }
                        }
                    }
                }
            }
    
            .upi-logo-container {
                display: flex;
                justify-content: end;
                align-items: center;
                // margin-top: 0.5rem;
                // width: 85%;
    
                .upi-logo {
                    // height: 2.5rem;
                    border-radius: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // width: 2.5rem;
                    // margin-right: 3rem;
                }
            }
            .upi-input {
                display: flex;
                width: 100%;
                // margin-bottom: 2rem;
                .upi-id
                {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin-top: 10px;
                padding: 7px;
                font-size: 20px;
                // margin-left: 22%;
                // margin-right: 4rem;
                .title{
                    color: #999999;
                }
            
                .upi-full {
                    border: 2px solid #DADADA;
                    border-radius: 8px;
                    width: 100%;
                    height: 40px;
                    padding: 0 10px;
                    font-size: 18px;
                    outline: none;
                    color: #999999;
                    font-weight: 600;
                }
                }
            }
        }
    
            .cod-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                // align-items: center;
                .cod-img{
                    border-radius: 8px;
                    margin-bottom: 0.5em;
                    // height: 16rem;
                }
                .bttn{
                    margin-top: 1rem;
                    margin-bottom: 3rem;
                    width: 100%;
                    .bottom-btn-container {
                        background: #0293A8;
                        border: 1px solid #0293A8;
                        padding-top: 5px;
                        border-radius: 10px;
                        font-size: 18px;
                        text-align: center;
                        margin-top: 1rem;
                        font-weight: 600;
                        letter-spacing: 0.03rem;
                        margin: auto;
                        color: #ffff;
                        // margin-top: 2rem;
                        // margin-top: 45px;
                        width: 100%;
                        height: 40px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    
    
    .back-btn{
        background-color: #0293A8;
        height: 32px;
        width: 32px;
        border-radius: 6px;
        border: 0px solid;
        color: rgb(255, 255, 255);
        // margin-left: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 300;
        // margin-top: 1em;
    }
    
    .footer-bottom-container{
   
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            border-top: 1px solid #545454;
            
        .footer-social-accounts{
            
            display: flex;
            justify-content: space-around;
            align-items: center;
        }.copyright{
            font-size: 16px;
            font-weight: 500;
        }
        .social-icon{
            font-size: 22px;
        }
    }
    
    .qr-title{
        margin: 12px 0px;
        color: #999999;
    }
    .admin-bg-con .stores-bg-con .h-btn-con .add-btn{
        height: unset;
        width: unset;
        padding: 5px 20px;
    }

@media screen and (width <= 620px) {
    .admin-bg-con, .admin-bg-con .stores-bg-con{
        gap: 30px;
    }
}