.expiry-bg-con{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg, #0293A8 0%, #EDFAFC 111.76%, rgba(217, 217, 217, 0) 111.77%);
    .ex-subscription{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        padding: 1rem;
        border-radius: 12px;
        .subscription-h{
            width: 90%;
            font-size: 20px;
            font-weight: 700;
            color: #363636;
        }
        .subscription-plan{
            width: 90%;
            display: grid;
            grid-template-columns: 50% 50%;
            // align-items: center;
            .plan-data{
                width: 80%;
                background-size: cover;
                margin-top: 3rem;
                padding: 18px;
                border: 1px solid #0293A8;
                border-radius: 12px;
            }
            .plans{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 3rem;
                .plan{
                    width: 45%;
                    margin: 2.5%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .plan-name{
                        text-align: center;
                        width: 100%;
                        padding: 12px;
                        border: 1px solid rgba(2, 147, 168, 0.15);
                        border-radius: 12px 12px 0px 0px;
                        font-size: 16px;
                        font-weight: 600;
                        background-image: linear-gradient(180deg, #0293A8 0%, #EDFAFC 111.76%, rgba(217, 217, 217, 0) 111.77%);
                        color: #EDFAFC;
                    }
                    .plan-details{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid rgba(2, 147, 168, 0.15);
                        border-radius: 0px 0px 12px 12px;
                        width: 100%;
                        padding: 12px;
                        background-color: #1dc9e325;
                        h1{
                            font-size: 20px;
                            line-height: 22px;
                            font-weight: 600;
                            color: #0293A8;
                            margin-bottom: 6px;
                        }
                        p{
                            color: #999999;
                            font-size: 14px;
                            line-height: 20px;
                            margin-bottom: 12px;
                        }
                        button{
                            width: 80%;
                            height: 30px;
                            border: 0px solid;
                            border-radius: 10px;
                            background-color: #0293A8;
                            color: #fff;
                        }
                    }
                }
            }
            // .plan-price{
            //     background-image: linear-gradient(101.05deg, rgba(2, 147, 168, 0.47) -1.04%, rgba(97, 198, 156, 0.286406) 38.9%, rgba(245, 255, 136, 0.44) 101.2%);;
            //     text-align: center;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     height: 50px;
            //     width: 240px;
            //     border-radius: 18px;
            //     border: 0px solid;
            //     .plan{
            //         font-size: 18px;
            //         font-weight: 500;
            //         .price{
            //             font-weight: 700;
            //             margin-left: 12px;
            //             font-size: 20px;
            //         }
            //     }
            // }
        }}
        .ex-payment-page{
            display: flex;
            justify-content: center;
            height: calc(100vh - 100px);
            overflow: hidden;
            overflow-y: scroll;
            padding-top: 4em;
            padding-bottom: 2em;
            background-color: #fff;
            width: 80%;
            border-radius: 12px;
            .left-secion{
                width: 40%;
                margin-right: 2%;
                display: flex;
                flex-direction: column;
                .sub-qr-code{
                    height: 220px;
                    width: 220px;
                }
                .upi-id-con{
                    width: 220px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 1rem;
                    color: #999999;
                }
                .payment-input{
                    background-color: transparent;
                    border: 1px solid#999999;
                    border-radius: 8px;
                    outline: none;
                    padding: 6px;
                }
                .buttons-con{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .can-btn{
                        border: 2px solid #0293A8;
                        border-radius: 12px;
                        color: #0293A8;
                        width: 140px;
                        height: 40px;
                        margin-right: 1em;
                    }
                    .done-btn{
                        border: 2px solid #0293A8;
                        border-radius: 12px;
                        color: #fff;
                        width: 140px;
                        height: 40px;
                        margin-left: 1em;
                        background-color: #0293A8;
                    }
                }
            }
            .right-section{
                width: 50%;
                margin-left: 2%;
                display: grid;
                grid-template-columns: 40% 50%;
                margin-top: 2em;
                .keys{
                    font-size: 14px;
                    color: #999999;
                    display: grid;
                    grid-template-columns: 100%;
                    grid-auto-rows: 35px;
                }
                .values{
                    font-size: 14px;
                    font-weight: 600;
                    color: #363636;
                    display: grid;
                    grid-template-columns: 100%;
                    grid-auto-rows: 35px;
                }
            }
        }
        .ex-success-view{
            width: 80%;
            height: 85%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 12px;
            .success-h{
                font-size: 28px;
                color: #0293A8;
                margin-bottom: 1rem;
            }
            .success-p{
                font-size: 16px;
                font-weight: 600;
                color: #999999;
                width: 30%;
                margin-bottom: 2em;
                text-align: center;
            }
            .success-img{
                width: 40%;
                background-size: cover;
            }
        }
}

.pay-title{
    font-size: 14px;
    color: #999999;
}

.logout-btn{
        color: aliceblue;
        background-color: #0293A8;
        // width: 150px;
        // height: 40px;
        // border-radius: 8px;
        // margin-top: 2rem;
        padding: 10px 40px;
        border-radius: 10px;
    }

.success-view{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .invoice{
            background-color: #EDFAFC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2em;
            border-radius: 8px;
            .payment-details-con{
                display: flex;
                margin-top: 1rem;
                .payment-keys{
                    margin-right: 1rem;
                    .key{
                        line-height: 36px;
                        font-weight: 600;
                    }
                }
                .payment-values{
                    .value{
                        line-height: 36px;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
        }
        .success-h{
            font-size: 28px;
            color: #0293A8;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
        .success-p{
            font-size: 16px;
            font-weight: 600;
            color: #999999;
            width: 30%;
            margin-bottom: 2em;
            text-align: center;
        }
        .success-img{
            width: 40%;
            background-size: cover;
        }
        .buttons-con{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 400px;
            margin-top: 1em;
            .print-button{
                height: 35px;
                width: 160px;
                border-radius: 6px;
                background-color: #0293A8;
                color: #EDFAFC;
                border: 2px solid #0293A8;
            }
            .cancel-button{
                height: 35px;
                width: 160px;
                border-radius: 6px;
                background-color: #EDFAFC;
                color: #0293A8;
                border: 2px solid #0293A8;
            }
        }
    }

    .payment-page{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 100px);
        overflow: hidden;
        .sub-payment-h{
            font-size: 28px;
            color: #252525;
            font-weight: 600;
        }
        .sub-pay-img{
            width: 100%;
            object-fit: contain;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
        .pay-now-btn{
            background-color:#0293A8;
            color: #fff;
            font-size: 18px;
            width: 160px;
            height: 35px;
            border-radius: 8px;
            border: 0px solid;
        }
    }