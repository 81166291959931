.payment-con{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: scroll;
    .payment-h{
        font-size: 22px;
    }
    .payment{
        display: flex;
        justify-content: space-between;
        gap: 30px;
        .qrcode{
            width: 200px;
            height: 200px;
            object-fit: cover;
        }
        .upi-id-label{
            font-size: 13px;
            color: #999999;
            // margin-top: 1rem;
        }
        .upi-id{
            border: 2px solid #0293A8;
            border-radius: 12px;
            padding: 8px;
            font-weight: 700;
            color: #999999;
        }

        .cod{
            display: flex;
            font-weight: 600;
            font-size: 18px;
            color: #999999;
            padding: 0 1rem;
        }
    }
    .edit-btn{
        color: aliceblue;
        background-color: #0293A8;
        // width: 120px;
        // height: 35px;
        border-radius: 10px;
        // margin-top: 2rem;
        padding: 10px 40px;
    }
    .payment-edit-form{
        width: 60%;
        display: flex;
        // grid-template-columns: 40% 40%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10vh;
        margin-top: 10vh;
        .label-text{
            font-size: 13px;
            margin-bottom: 2px;
            margin-top: 10px;
            font-weight: 600;
        }
        .qr-input{
            width: 16vw;
            background-size: cover;
            height: 16vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 2px solid #0293A8;
            border-radius: 12px;
            .add-icon{
                margin-bottom: 18px;
            }
            .qr-p{
                font-size: 14px;
                font-weight: 500;
            }
        }
        .upi-input{
            display: flex;
            flex-direction: column;
            .upiId{
                border: 2px solid #0293A8;
                border-radius: 12px;
                padding: 8px;
                font-weight: 700;
                color: #999999;
                outline: none;
            }
        }
        .cod-img{
            width: 200px;
            height: 200px;
        }
        
    }
    .cancel-save-btn{
        display: flex;
        justify-content: space-around;
        .cancelBtn{
            width: 160px;
            height: 35px;
            font-weight: 700;
            border: 2px solid #999999;
            border-radius: 8px;
            background-color: rgb(255, 255, 255);
            margin-right: 12px;
        }
        .saveBtn{
            width: 160px;
            height: 35px;
            font-weight: 700;
            border: 0px solid;
            border-radius: 8px;
            background-color: #0293A8;
            margin-left: 12px;
            color: aliceblue;
        }
    }
}


.imgContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
}


@media screen and (width <= 620px) {
    .payment-con .payment{
        flex-direction: column;
    }
    .payment-con{
        gap: 20px;
        padding: 10px;
    }
}
@media screen and (width <= 400px) {
    .payment-con .payment{
        flex-direction: row;
    }
    .payment-con{
        gap: 20px;
        padding: 10px;
    }
    .qrcode{
        width: 150px !important;
        height: 150px !important;
    }
    .payment-h{
        font-size: 14px !important;
    }

    .cod-img{
        width: 100px;
        height: 100px;

    }
    .cod{
    font-size: small !important;
    padding: 0 0 !important; 
    width: 90px;
    height: 80px;
    flex-wrap: wrap;
}
   .payment-edit-form{
    flex-direction: column !important;
    margin-top: 0px !important;
    width: 90% !important;
   }
   .qr-input{
    width: 250px !important;
    height: 100px !important;
   }
}